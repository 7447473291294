<template>
  <ClientOnly>
    <!-- In Cart But out of Stock -->
    <div class="relative" v-bind="$attrs">
      <VyButton
        v-if="(isOutOfStock || (manageInventory && maxQty <= 0)) && qty > 0"
        :size="size"
        :theme="theme"
        color="danger"
        shape="rounded"
        :label="$t('remove')"
        :class="buttonClass"
        :loading="isLoading"
        @click="change(0)"
      />

      <!-- Out of Stock -->
      <VyButton
        v-else-if="isOutOfStock || (manageInventory && maxQty <= 0)"
        :label="$t('out_of_stock')"
        :size="size"
        :theme="theme"
        color="gray"
        shape="rounded"
        :class="buttonClass"
        disabled
      />

      <!-- Not Available -->
      <VyButton
        v-else-if="notAvailable"
        :label="$t('not_available')"
        :class="buttonClass"
        :size="size"
        :theme="theme"
        color="gray"
        shape="rounded"
        disabled
      />

      <!-- In Cart -->
      <div
        v-else-if="productInCart(id) && allowQty"
        class="flex items-stretch rounded"
        :class="qtyClass"
      >
        <VyButton
          class="rounded-s"
          icon="MdiMinus"
          color="primary"
          theme="solid"
          shape="square"
          :size="size"
          :loading="isRemoving"
          @click="change(localQty - 1)"
        />
        <input
          ref="qty"
          type="number"
          class="add-to-cart__qty"
          :value="localQty"
          step="1"
          min="1"
          @input="qtyInput($event.target.value)"
          @blur="qtyBlur($event)"
        />
        <VyButton
          class="rounded-e"
          icon="MdiPlus"
          :size="size"
          color="primary"
          theme="solid"
          shape="square"
          :loading="isAdding"
          @click="change(localQty + 1)"
        />
      </div>

      <!-- In Cart but qty can not be set -->
      <VyButton
        v-else-if="productInCart(id)"
        :class="buttonClass"
        :size="size"
        :theme="theme"
        color="danger"
        shape="rounded"
        :label="$t('remove')"
        :loading="isLoading"
        @click="change(0)"
      />

      <!-- Add To Cart -->
      <VyButton
        v-else
        :class="buttonClass"
        :size="size"
        :theme="theme"
        color="primary"
        shape="rounded"
        :label="$t('add_to_cart')"
        :loading="isLoading"
        @click="change(1)"
      />
    </div>
  </ClientOnly>
</template>

<script>
import { useCartStore } from "@/store/cart.js";
import { mapState, mapActions } from "pinia";
import { useDebounceFn } from "@vueuse/core";

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    max: {
      default: null,
    },
    isOutOfStock: Boolean,
    notAvailable: Boolean,
    manageInventory: Boolean,
    allowQty: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "md",
    },
    theme: {
      type: String,
      default: "muted",
    },
    buttonClass: {
      type: [String, Array],
      default: null,
    },
    qtyClass: {
      type: [String, Array],
      default: null,
    },
  },

  emits: ["change"],

  data() {
    return {
      isLoading: false,
      isAdding: false,
      isRemoving: false,
      localQty: 0,
      changeDebounce: useDebounceFn(function (value) {
        this.isLoading = true;
        if (value > this.qty) {
          this.isAdding = true;
        } else {
          this.isRemoving = true;
        }

        this.upsertCart({ id: this.id, qty: value }).then(() => {
          if (this.manageInventory && value > this.qty) {
            alert(this.$t("limited_stock_message"));
          }

          this.localQty = this.qty;
          this.isLoading = false;
          this.isAdding = false;
          this.isRemoving = false;
          this.$emit("change", value);
        });
      }, 1500),
    };
  },

  computed: {
    ...mapState(useCartStore, { productInCart: "product" }),

    qty() {
      return this.productInCart(this.id)?.qty || 0;
    },

    maxQty() {
      if (this.max == null) {
        return 999;
      }
      return this.max;
    },
  },

  watch: {
    qty(newValue) {
      this.localQty = newValue;
    },
  },

  created() {
    this.localQty = this.qty;
  },

  methods: {
    ...mapActions(useCartStore, ["upsertCart"]),

    change(value) {
      //When adding to cart for first time, show the loader immediately to prevent lag in response.
      //In other cases, the loader will show in debounced manner.
      if (value == 1) {
        this.isLoading = true;
      }
      this.localQty = value;
      this.changeDebounce(this.localQty);
    },

    qtyBlur(e) {
      if (!e.target.value) {
        e.target.value = this.qty;
      }
    },

    qtyInput(value) {
      if (value === "") value = 0;
      if (value < 0) value = 0;

      value = Math.abs(parseInt(value));

      // Update UI field to skip non integer value
      this.$refs.qty.value = value;

      // Actually set value
      this.change(value);
    },
  },
};
</script>

<style>
.add-to-cart__qty {
  @apply bg-gray-200 w-10 text-center appearance-none dark:bg-gray-800 dark:text-gray-300;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-spin-button {
    -moz-appearance: textfield;
  }
}
</style>
